import {HttpInterceptorFn} from '@angular/common/http';
import {switchMap} from 'rxjs';
import {inject} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.startsWith('/assets/zitadel.config.json')) {
    return next(req);
  }

  const oidcSecurityService = inject(OidcSecurityService);

  // Obtaining the configuration of the OIDC client
  return oidcSecurityService.getConfiguration().pipe(
    switchMap(config => {
      // User is probably not yet authenticated, skipping request for now
      // if (!config.secureRoutes) {
      //   return next.handle(req);
      // }

      // Checking if the URL has to be secured
      if (config.secureRoutes.find(x => req.url.startsWith(x))) {
        // URL has to be secured because she is in the secureRoutes list
        return oidcSecurityService.getAccessToken().pipe(
          switchMap(accessToken => {

            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            return next(req);
          }),
        );
      }

      // URL hasn't to be secured
      return next(req);
    }),
  );
};
