import {enableProdMode, LOCALE_ID, ErrorHandler, APP_INITIALIZER, importProvidersFrom} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {EnterOutline} from '@ant-design/icons-angular/icons';
import {IconDefinition} from '@ant-design/icons-angular';
import {map} from 'rxjs';
import {AuthModule, StsConfigLoader, OpenIdConfiguration, StsConfigHttpLoader} from 'angular-auth-oidc-client';
import {NgOptimizedImage, registerLocaleData} from '@angular/common';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {provideAnimations} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {NzNotificationServiceModule} from 'ng-zorro-antd/notification';
import {OverlayModule} from '@angular/cdk/overlay';
import {BrowserModule, bootstrapApplication} from '@angular/platform-browser';
import {provideHttpClient, HttpClient, withInterceptors} from '@angular/common/http';
import {provideRouter, Router} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {NZ_I18N, fr_FR, NZ_DATE_CONFIG, provideNzI18n, NZ_DATE_LOCALE, en_US} from 'ng-zorro-antd/i18n';
import BigNumber from 'bignumber.js';
import fr from '@angular/common/locales/fr';
import {routes} from './app/app.routes';
import {fr as frDate} from 'date-fns/locale';
import {authInterceptor} from './app/interceptors/auth.interceptor';

export const httpLoaderFactory = (httpClient: HttpClient) => {
  const config$ = httpClient.get<OpenIdConfiguration>(`/assets/zitadel.config.json`).pipe(
    map((customConfig: OpenIdConfiguration) => {
      return {
        authority: customConfig.authority,
        redirectUrl: customConfig.redirectUrl,
        postLogoutRedirectUri: customConfig.postLogoutRedirectUri,
        clientId: customConfig.clientId,
        secureRoutes: customConfig.secureRoutes,
        scope: 'openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
      };
    }),
  );

  return new StsConfigHttpLoader(config$);
};

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_HALF_EVEN,
});

const icons: IconDefinition[] = [EnterOutline];

registerLocaleData(fr);

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://159bdabe5bad4413a48c450a607fce63@o4504146822823936.ingest.sentry.io/4504146825248768',
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    release: environment.buildNumber,
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    // TODO : change that to fr_FR on next NgZorro update
    provideNzI18n(en_US),
    importProvidersFrom(
      BrowserModule,
      OverlayModule,
      NzNotificationServiceModule,
      FormsModule,
      NzLayoutModule,
      NzMenuModule,
      NzIconModule,
      NzDropDownModule,
      NzToolTipModule,
      NgOptimizedImage,
      AuthModule.forRoot({
        loader: {
          provide: StsConfigLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      NzIconModule.forRoot(icons),
      NzPopoverModule,
      NzGridModule,
      NzCardModule,
      NzButtonModule,
      NzTypographyModule,
    ),
    {
      provide: NZ_I18N,
      useValue: fr_FR,
    },
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'tinymce/tinymce.min.js',
    },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
      },
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    NzModalService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: NZ_DATE_LOCALE,
      useValue: frDate,
    },
  ],
}).catch(err => console.error(err));
